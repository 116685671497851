import { BsSearch } from "react-icons/bs";

export default function NoResults({ title }: any) {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="mb-2">
        <BsSearch />
      </div>
      <div className="text-gray-500 text-sm">{title}</div>
    </div>
  );
}
